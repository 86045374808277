
    import { defineComponent, reactive, ref, onMounted, computed } from 'vue';
    import useFetchCommon from "@/hooks/useFetchCommon";
    import { getDealerType, getDealerGroup, getDealerDetail, getAllBu, getDealerHistory } from "@/API/dealerMaster";
    import { DealerType, DealerGroup, Bu } from "@/views/DealerMasterData/Region/types";
    import { DealerDetail, DealerChangeHistoryProps } from './types';
    import { TableState } from 'ant-design-vue/es/table/interface';
    import { useI18n } from 'vue-i18n';
    type Pagination = TableState['pagination'];

    export default defineComponent({
        name: "DealerView",
        props: {
            dealerId: {
                type: String
            }
        },
        setup(props) {
            const buValue = ref<Bu[]>([]);

            const { data: dealerGroup } = useFetchCommon<DealerGroup[]>({ initData: [], fetchApi: getDealerGroup });

            const { data: tableData, pagination, refreshFetch } = useFetchCommon<DealerChangeHistoryProps[]>({ params: { dealerId: props.dealerId },initData: [], fetchApi: getDealerHistory });

            const initData = {
                tmDealerInfoDto: {
                    id: '',
                    dealerCode: '',
                    gsCode: '',
                    gcCode: '',
                    statusName: '',
                    nameCn: '',
                    nameEn: '',
                    dealerGroup: '',
                    dealerGroupNameCn: '',
                    dealerGroupNameEn: '',
                    provinceEn: '',
                    provinceCn: '',
                    cityCn: '',
                    cityEn: '',
                    legalNameCn: '',
                    legalNameEn: '',
                    additionalNameCn: '',
                    additionalNameEn: '',
                    addressCn: '',
                    addressEn: '',
                    zipCode: '',
                    format: '',
                    contactEmail: '',
                    fixPhone: '',
                    mobilePhone: '',
                    fax: '',
                    mbafcCode: null,
                    swtCode: null,
                    vendorCode: null,
                    isGovernmentRelated: 0,
                    languageValue: null,
                    dosCode: null
                },
                tmDealerDealerTypeMappingDto: [],
                tmDealerRegionMappingDto: []
            }

            const data = reactive<DealerDetail>(initData);

            const type = ref<DealerType[]>([]);

            const bu = ref<Bu[]>([]);

            const { t } = useI18n()

            const columns = [
                {
                    title: t('dealerDataEdit.changeDate'),
                    dataIndex: 'createdAt',
                    key: 'createdAt'
                },
                {
                    title: t('dealerDataEdit.changeBefore'),
                    dataIndex: 'beforeNameCn',
                    key: 'beforeNameCn',
                    slots: { customRender: 'before' }
                },
                {
                    title: t('dealerDataEdit.changeAfter'),
                    dataIndex: 'afterNameCn',
                    key: 'afterNameCn',
                    slots: { customRender: 'after' }
                },
                {
                    title: t('dealerDataEdit.operationUser'),
                    dataIndex: 'createdBy',
                    key: 'createdBy'
                }
            ];

            const dealerInfoMapping = reactive([
                [
                    {
                        name: t('dealerDataEdit.legalNameCn'),
                        key: 'legalNameCn',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.legalNameEn'),
                        key: 'legalNameEn',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.additionalNameCn'),
                        key: 'additionalNameCn',
                        type: 'text'
                    }
                ],
                [
                    {
                        name: t('dealerDataEdit.additionalNameEn'),
                        key: 'additionalNameEn',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.provinceCn'),
                        key: 'provinceCn',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.provinceEn2'),
                        key: 'provinceEn',
                        type: 'text'
                    }
                ],
                [
                    {
                        name: t('dealerDataEdit.cityCn'),
                        key: 'cityCn',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.cityEn2'),
                        key: 'cityEn',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.addressCn'),
                        key: 'addressCn',
                        type: 'text'
                    }
                ],
                [
                    {
                        name: t('dealerDataEdit.addressEn'),
                        key: 'addressEn',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.zipCode'),
                        key: 'zipCode',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.format'),
                        key: 'format',
                        type: 'text'
                    }
                ],
                [
                    {
                        name: t('dealerDataEdit.contactEmail'),
                        key: 'contactEmail',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.fixPhone'),
                        key: 'fixPhone',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.dealerGroup2'),
                        key: 'dealerGroupNameEn',
                        type: 'text'
                    },
                ],
                [
                   {
                        name: t('dealerDataEdit.swtCode'),
                        key: 'swtCode',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.vendorCode'),
                        key: 'vendorCode',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.mbafcCode'),
                        key: 'mbafcCode',
                        type: 'text'
                    },
                ],
                [
                    {
                        name: t('dealerDataEdit.languageValue'),
                        key: 'languageValue',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.dosCode'),
                        key: 'dosCode',
                        type: 'text'
                    },
                    {
                        name: t('dealerDataEdit.isGovernmentRelated'),
                        key: 'isGovernmentRelated',
                        type: 'radio',
                        source: [
                            {
                                name: t('dealerDataEdit.yes'),
                                value: 1
                            },
                            {
                                name: t('dealerDataEdit.no'),
                                value: 0
                            }
                        ]
                    }
                ],
                [
                    {
                        name: t('dealerDataEdit.dealer400Code'),
                        key: 'dealer400Code',
                        type: 'text',
                    },
                    {
                        name: '',
                        key: '',
                        type: 'text',
                    },
                    {
                        name: '',
                        key: '',
                        type: 'text',
                    },
                ],
                [
                    {
                        name: '',
                        key: '',
                        type: 'text'
                    }
                ]
            ]);

            const getDealerDetailData = async () => {
                const _dealerType = await getDealerType();
                const _bu = await getAllBu();
                const dealerDetailData = await getDealerDetail({ dealerId: props.dealerId as string });

                if (dealerDetailData.tmDealerDealerTypeMappingDto.length > 0) {
                    _dealerType.forEach(item => {
                        const result = dealerDetailData.tmDealerDealerTypeMappingDto.filter(ele => ele.dealerTypeId == item.id);
                        if (result.length > 0){
                            item.poccDate = result[0].poccDate;
                            item.uuid = result[0].id;
                        } else {
                            item.poccDate = '';
                        }
                    })
                }

                if (dealerDetailData.tmDealerRegionMappingDto.length > 0) {
                    _bu.forEach(bu => {
                        const result = dealerDetailData.tmDealerRegionMappingDto.filter(ele => ele.buId == bu.id);
                        if (result.length > 0){
                            bu.buRegionValue = (() => bu.regionDto?.filter(item => item.id === result[0].regionId)[0] ?? null)();
                            bu.uuid = result[0].id;
                            buValue.value.push(bu);
                        } else {
                            bu.buRegionValue = null;
                            bu.uuid = '';
                        }
                    })
                }

                Object.assign(data, dealerDetailData);

                type.value = _dealerType.filter(item => item.poccDate);
                bu.value = _bu
            }

            const paginationData = computed(() => ({
                total: pagination.total,
                current: pagination.page,
                pageSize: pagination.size,
            }));

            const handleTableChange = (pag: Pagination) => {
                pagination.page = pag?.current as number;
                refreshFetch(false);
            }

            onMounted(() => {
                getDealerDetailData();
            })

            return {
                type,
                data,

                dealerGroup,
                bu,
                buValue,

                dealerInfoMapping,

                paginationData,
                tableData,
                columns,

                handleTableChange
            }
        }
    })
